import { useState } from "react";
import SidebarModule from "../../components/sidebar-module";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { sidebarSelector } from "../../redux/sidebar/sidebar.slice";
import {
  getAuth,
  getCategories,
  getProjects,
} from "../../redux/settings/settings.selector";
import { ICategoryResponse, IProjectResponse } from "../../types/responses";
import {
  AISuggestionIcon,
  ContentToolIcon,
  DashboardIcon,
  ThreeDotIcon,
  ToggleSideBarIcon,
} from "assets/icons";
import { setShow } from "../../redux/sidebar/sidebar.slice";
import { isMobile } from "react-device-detect";

const View = () => {
  const [target, setTarget] = useState<string | null>(null);
  const { show } = useAppSelector(sidebarSelector);
  const categories = useAppSelector(getCategories);
  const projects = useAppSelector(getProjects);
  const user = useAppSelector(getAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modules = [
    {
      id: "1",
      key: "dashboard",
      name: "Dashboard",
      icon: <DashboardIcon />,
      hasChild: false,
      firstShow: false,
      path: "/",
      status: "enable",
    },
    {
      id: "2",
      key: "tools",
      name: "Content Tools",
      icon: <ContentToolIcon />,
      hasChild: true,
      childs: categories
        ?.map((cate: ICategoryResponse) => {
          return {
            ...cate,
            childs: projects
              .filter((pro: IProjectResponse) => pro.category_id === cate._id)
              .map((pro: IProjectResponse) => ({
                ...pro,
                path: `project/${pro._id}`,
              })),
          };
        })
        .sort(
          (prev: ICategoryResponse, next: ICategoryResponse) =>
            prev.order - next.order
        ),
      firstShow: true,
      path: "/",
      status: "enable",
    },
    {
      id: "2",
      key: "chatgpt4",
      name: "AI Suggestions",
      icon: <AISuggestionIcon />,
      hasChild: false,
      firstShow: false,
      path: "/chat",
      status: "enable",
    },
    // {
    //   id: "3",
    //   key: "template",
    //   name: "Template",
    //   icon: "template.svg",
    //   hasChild: false,
    //   firstShow: false,
    //   path: "/",
    // },
    {
      id: "3",
      key: "chatpdf",
      name: "ChatPDF",
      icon: <img src="/images/icons/pdf.svg" alt="" />,
      hasChild: false,
      firstShow: false,
      path: "/pdf",
      status: "enable",
    },
  ];

  const handleChangeTarget = (key: string) => {
    if (target !== key) {
      setTarget(key);
    }
    if (target === key) {
      setTarget(null);
    }
    isMobile && dispatch(setShow(false));
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      {show && (
        <div className="h-[100vh] bg-white shadow-left  fixed z-40 flex md:top-0 opacity-100 transition-all duration-300 md:sticky md:h-screen  w-full md:w-auto md:min-w-min overflow-hidden">
          <div className="relative w-full md:w-[284px]  border-b">
            <div className="flex items-center justify-center border-b px-6 h-16">
              <Link to="/" className="">
                <img
                  src="/images/copypress-logo-purple.svg"
                  alt="logo"
                  className="md:block w-[150px]"
                />
              </Link>
            </div>
            {user && (
              <div className="flex items-center gap-x-5 pt-[34px] pb-10 px-6">
                <img src={user?.avatar} alt="" className="w-[35px] h-[35px]" />
                <div className="flex flex-col">
                  <p className="text-sm font-medium text-copypress_blue">
                    {user?.full_name}
                  </p>
                  <p className="text-[11px] text-copypress_gray-4 font-medium">
                    {user?.email}
                  </p>
                </div>
              </div>
            )}
            <div className="pr-3 pb-5 border-b flex-col mt-5">
              {modules
                .filter((module) => module.status === "enable")
                .map((module) => (
                  <SidebarModule
                    target={target}
                    key={module.id}
                    ckey={module.key}
                    name={module.name}
                    icon={module.icon}
                    hasChild={module.hasChild}
                    childs={module.childs}
                    onTarget={(e) => handleChangeTarget(e)}
                    firstShow={module.firstShow}
                    path={module.path}
                  />
                ))}
            </div>

            <div className="fixed bottom-0 w-full md:w-[284px]">
              {user && (
                <>
                  {" "}
                  <Link
                    to={"/setting"}
                    className="flex items-center gap-x-3 cursor-pointer hover:bg-gray-100 py-2 px-6"
                    // onClick={() => setActiveTab(sideBarBottomItems.SETTINGS)}
                  >
                    <p className={`flex items-center w-5 h-5`}>
                      <ThreeDotIcon />
                    </p>
                    <p className={`text-[13px] font-medium `}>Settings</p>
                  </Link>
                  <Link
                    to={"/subscription"}
                    className="flex items-center gap-x-3 cursor-pointer hover:bg-gray-100 py-2 px-6"
                    // onClick={() => setActiveTab(sideBarBottomItems.SETTINGS)}
                  >
                    <p className={`flex items-center w-5 h-5`}>
                      <img
                        src={`/images/icons/subscription.svg`}
                        alt="icon"
                        className="w-4 inline-block mr-2"
                      />
                    </p>
                    <p className={`text-[13px] font-medium`}>Subscription</p>
                  </Link>
                  <div
                    className="flex items-center gap-x-3 cursor-pointer hover:bg-gray-100 py-2 px-6"
                    onClick={(_) => handleLogout()}
                  >
                    <p className="flex items-center  w-5 h-5">
                      <img
                        src={`/images/icons/logout.svg`}
                        alt="icon"
                        className="w-4 inline-block mr-2"
                      />
                    </p>
                    <p className="text-[13px] font-medium ">Logout</p>
                  </div>
                </>
              )}
              <div className="flex items-center gap-x-3 cursor-pointer hover:bg-gray-100 py-2 px-6">
                <p className="flex items-center text-copypress_gray-5 w-5 h-5">
                  <ToggleSideBarIcon />
                </p>
                <p
                  className="text-[13px] font-medium "
                  onClick={(_) => isMobile && dispatch(setShow(!show))}
                >
                  Toggle sidebar
                </p>
              </div>
            </div>

            {/* <div className="absolute bottom-0 mb-3 md:mb-8 w-[calc(100%-0.75rem*2)] md:w-[calc(100%-2rem*2)]">
              <Button
                Icon={WhiteCrown}
                name="Upgrade to Pro"
                onClick={() => navigate("/subscription")}
                className="!bg-active"
              />
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default View;
