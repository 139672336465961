import AWS from "aws-sdk";
import { v4 } from "uuid";
import path from "path-browserify";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  region: "ap-southeast-1",
  signatureVersion: "v4",
});
const s3 = new AWS.S3();

export async function uploadToS3(
  bucket: string,
  folder: string,
  file: File,
  callback?: (per: number) => void
) {
  try {
    if (!file) {
      return;
    }
    const params = {
      Bucket: bucket,
      Key: `${folder}/${v4()}${path.extname(file.name)}`,
      Body: file,
    };
    const upload = s3.upload(params);
    upload.on("httpUploadProgress", ({ loaded, total }) => {
      callback && callback(Math.round((100 * loaded) / total));
    });
    const { Location, Key, Bucket } = await upload.promise();
    return {
      filePath: Location,
      key: Key,
      bucket: Bucket,
      name: file.name,
      type: file.type,
    };
  } catch (error) {
    console.log(error);
    throw new Error("Something went error!");
  }
}

export async function deleteOnS3(bucket: string, key: string) {
  try {
    const params = {
      Bucket: bucket,
      Key: key,
    };
    const result = await s3.deleteObject(params).promise();
    if (result) {
      return `Deleted file key: ${key}`;
    } else {
      throw new Error("Can not delete file!");
    }
  } catch (error) {
    throw new Error("Something went error!");
  }
}
