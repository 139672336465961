/* eslint-disable no-unreachable */
import { useEffect, useState } from "react";
import { GoogleIcon } from "assets/icons";
import TopQueriesTable from "./components/top-queries-table";
import TopPagesTable from "./components/top-pages-table";
import { useGoogleLogin } from "@react-oauth/google";
import AuthService from "services/auth";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getAuthsAsync } from "redux/settings/settings.action";
import { getAuth } from "redux/settings/settings.selector";
import SettingService from "services/settings";
import UserService from "services/users";
import Modal from "components/modal";
import { createPortal } from "react-dom";
import Button from "components/button";
import Radio from "components/radio";
import Loading from "components/loading";

const Dashboard = () => {
  const [showConnectGoogle, setShowConnectGoogle] = useState(false);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(getAuth);
  const [dataGoogleSearch, setDataGoogleSearch] = useState({
    pages: {
      rows: [],
    },
    queries: {
      rows: [],
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [urls, setUrls] = useState([]);
  const [urlSelectecd, setUrlSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  useEffect(() => {
    if (auth) {
      getGoogleSearch();
    }
  }, [auth]);

  useEffect(() => {}, [urls]);

  const handleLoginGoogle = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/webmasters.readonly",
    onSuccess: async (tokenReponse) => {
      try {
        setLoadingLogin(true);
        const res = await AuthService.loginByGoogle({
          body: {
            accessToken: tokenReponse.access_token,
            type: "google",
          },
        });
        localStorage.setItem("access_token", res.token);
        getSiteUrls();
      } catch (error) {}
    },
    onError: (err: any) => {},
  });

  const getGoogleSearch = async () => {
    try {
      const res: any = await SettingService.getGoogleSearch();
      if (res) {
        setDataGoogleSearch(res);
      }
    } catch (error) {}
  };

  const getSiteUrls = async () => {
    try {
      const res: any = await SettingService.getSiteUrls();
      if (res) {
        if (res.length > 0) {
          setUrls(res);
          setOpenModal(true);
        } else {
          setLoadingLogin(false);
          dispatch(getAuthsAsync());
        }
      }
    } catch (error) {
      setLoadingLogin(false);
      dispatch(getAuthsAsync());
    }
  };

  const updateUrlUser = async (isCancel = false) => {
    try {
      setLoading(true);
      await UserService.update({
        body: {
          url: isCancel ? "" : urlSelectecd,
        },
      });
      setLoadingLogin(false);
      setLoading(false);
      setOpenModal(false);
      dispatch(getAuthsAsync());
    } catch (error) {
      setLoading(false);
      setLoadingLogin(false);
    }
  };

  return (
    <>
      {auth && (
        <div className="flex flex-col py-10 px-5 mt-16 md:mt-0">
          <div className="grid grid-cols-3 gap-4 ">
            <div className="flex flex-col h-[144px] items-center bg-copypress_green col-span-3 md:col-span-1">
              <p className="text-[64px] font-bold text-white">34</p>
              <p className="text-base text-white font-bold">
                Pages found in Google
              </p>
            </div>
            <div className="flex flex-col h-[144px] items-center bg-copypress_red col-span-3 md:col-span-1">
              <p className="text-[64px] font-bold text-white">12</p>
              <p className="text-base text-white font-bold">
                Pages not found in Google
              </p>
            </div>
            <div className="flex flex-col h-[144px] items-center bg-copypress_blue-2 col-span-3 md:col-span-1">
              <p className="text-[64px] font-bold text-white">2</p>
              <p className="text-base text-white font-bold">
                Pages with 404 errors
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2">
            <div className="col-span-2 lg:col-span-1 bg-white border shadow-md rounded-[10px] overflow-hidden my-2 lg:mr-2">
              <TopQueriesTable data={dataGoogleSearch.queries} />
            </div>
            <div className="col-span-2 lg:col-span-1 bg-white border shadow-md rounded-[10px] overflow-hidden my-2 lg:ml-2">
              <TopPagesTable data={dataGoogleSearch.pages} />
            </div>
          </div>
        </div>
      )}
      {!auth && showConnectGoogle && (
        <div className="flex flex-col items-center justify-center h-[100vh] lg:h-full gap-y-1 bg-copypress_gray-5/80">
          <p className="text-lg font-medium text-white">
            Welcome - Let’s Get Started
          </p>
          <p className="text-sm font-medium text-white">
            Connect Your Google Analytics & Search Console Data
          </p>
          <button
            disabled={loadingLogin}
            onClick={() => {
              handleLoginGoogle();
            }}
            className="flex items-center gap-x-4 px-[9px] py-[7px] bg-white rounded-[10px] mt-[60px] hover:bg-copypress_gray-6"
          >
            {loadingLogin && (
              <Loading sub={false} iStyle="w-5 h-5 text-green-400" />
            )}
            {!loadingLogin && <GoogleIcon />}
            <p className="text-xl text-copypress_blue-1">Connect Accounts</p>
          </button>
        </div>
      )}
      {!auth && !showConnectGoogle && (
        <div className="flex flex-col items-center justify-center  gap-y-1  bg-copypress_gray-6 h-[100vh] lg:h-full">
          <p className="text-lg font-medium text-black">
            Welcome - Let's Get Started
          </p>
          <p className="text-sm text-black font-medium">
            Connect your website so we can start
          </p>
          <p className="text-sm text-black font-medium">
            giving you product marketing recommendations.
          </p>
          <button
            onClick={() => setShowConnectGoogle(true)}
            className="flex items-center justify-center p-3 rounded-lg bg-copypress_purple text-sm font-medium hover:opacity-80 text-white outline-none mt-4 !ring-transparent border-none !ring-0"
          >
            Connect Website
          </button>
        </div>
      )}
      {openModal &&
        createPortal(
          <Modal>
            <div className="max-w-[350px] bg-white shadow-md p-5 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="text-[18px] font-bold text-center">
                Select the website you want to get analytical data
              </div>
              <hr />
              <div className="justify-center mt-5">
                {urls.map((item: string, index: any) => (
                  <Radio
                    key={index}
                    id={item}
                    name={item}
                    checked={item === urlSelectecd}
                    onCheck={(_) => setUrlSelected(item)}
                    className="mt-5"
                  />
                ))}
              </div>
              <div className="mr-0 ml-auto w-fit">
                <div className="mt-4 flex gap-3">
                  <Button
                    disabling={loading}
                    name="Cancel"
                    type="fourth"
                    onClick={(_) => updateUrlUser(true)}
                    className="!w-28"
                  />
                  <Button
                    disabling={loading}
                    name="Submit"
                    loading={loading}
                    onClick={(_) => updateUrlUser(false)}
                    className="!w-44"
                  />
                </div>
              </div>
            </div>
          </Modal>,
          document.body
        )}
    </>
  );
};

export default Dashboard;
