import { CloseIcon, WarnIcon } from "assets/icons";
import Action from "components/action";
import Button from "components/button";

interface IProps {
  onClose: (e?: any) => void;
  onSubmit: (e?: any) => void;
}

const View = ({ onClose, onSubmit }: IProps) => {
  return (
    <div className="w-4/5 lg:w-2/5 bg-white rounded-lg drop-shadow-md absolute left-1/2 -translate-x-1/2 top-40">
      <div className="p-6">
        <label className="font-bold text-typera-secondary text-base">
          Delete Chat
        </label>
        <p className="font-medium text-sm leading-6 text-[#222B45] mt-3">
          Do you really want to delete this chat?
        </p>
        <p className="flex items-center gap-2 text-[#FFAA00] font-bold mt-2">
          <span className="w-4 h-4">
            <WarnIcon />
          </span>
          <span className="text-sm">
            This will permanently delete the chat and PDF content form Copypress.ai
          </span>
        </p>
        <div className="mr-0 ml-auto w-fit">
          <div className="mt-4 flex gap-3">
            <Button
              name="Cancel"
              type="fourth"
              onClick={onClose}
              className="!w-28"
            />
            <Button
              name="Delete"
              onClick={() => {
                onSubmit();
              }}
              className="!w-36 !bg-[#FF3D71]"
            />
          </div>
        </div>
      </div>
      <div className="absolute top-4 right-4">
        <Action Icon={CloseIcon} id="close" onAction={onClose} />
      </div>
    </div>
  );
};

export default View;
