/* eslint-disable react-hooks/exhaustive-deps */
import Project from "../../../layouts/project";
import ResultBar from "../../../layouts/result-bar";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setResults } from "../../../redux/results/result.slice";
import { getProjects } from "../../../redux/settings/settings.selector";

const View = () => {
  const { project_id } = useParams();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(getProjects);

  useEffect(() => {
    if (project_id) {
      dispatch(setResults([]));
    }
  }, [project_id]);

  return (
    <div className="h-screen md:h-full flex flex-col md:flex-row mt-20 md:mt-0">
      <div className="flex-1 bg-white m-5">
        <Project
          project={projects.find((project: any) => project._id === project_id)}
        />
      </div>
      <div className="flex-1">
        <ResultBar
          project={projects.find((project: any) => project._id === project_id)}
        />
      </div>
    </div>
  );
};

export default View;
