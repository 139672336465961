/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import Head from "../../layouts/head";
import Sidebar from "../../layouts/sidebar";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setShow } from "../../redux/sidebar/sidebar.slice";
import {
  getCategoriesAsync,
  getLanguagesAsync,
  getProjectsAsync,
  getProjectChatsAsync,
  getPromptChatsAsync,
  getAuthsAsync,
} from "../../redux/settings/settings.action";
import { getAuth } from "redux/settings/settings.selector";

const View = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(getAuth);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(getAuthsAsync());
    }
  }, [localStorage.getItem("access_token")]);

  useEffect(() => {
    if (auth) {
      dispatch(getCategoriesAsync());
      dispatch(getProjectsAsync());
      dispatch(getLanguagesAsync());
      dispatch(getProjectChatsAsync());
      dispatch(getPromptChatsAsync());
    }
  }, [auth]);

  useEffect(() => {
    const responsive = () => {
      if (window.innerWidth > 640) {
        dispatch(setShow(true));
      } else {
        dispatch(setShow(false));
      }
    };
    window.addEventListener("resize", responsive);
    return () => {
      window.removeEventListener("resize", responsive);
    };
  });

  return (
    <div className="flex flex-col md:flex-row bg-copypress_gray-1">
      <Sidebar />
      <div className="flex flex-col w-full">
        <Head />
        <Outlet />
      </div>
    </div>
  );
};

export default React.memo(View, () => {
  return true;
});
