/* eslint-disable react-hooks/exhaustive-deps */
import Head from "layouts/head";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getPlansAsync } from "redux/settings/settings.action";
import { getAuth } from "redux/settings/settings.selector";
import Button from "components/button";
import { IObject } from "types/types";
import { formatNumber } from "utils/format";
import Progress from "components/progress";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "assets/icons";
import StripeService from "services/stripe";

const View = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(getAuth);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPlansAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCount = (name: string, plan: IObject) => {
    if (plan.package[name] === null) {
      return "Unlimited";
    }
    if (!plan.package[name] || +plan.package[name] === 0) {
      return 0;
    }
    return `${formatNumber(
      +plan.package[name] - (plan.count[name] <= 0 ? 0 : plan.count[name])
    )}
    / ${formatNumber(plan.package[name])}`;
  };

  const manageSubscription = async () => {
    try {
      if (auth) {
        const data = await StripeService.manageSubscription({
          body: {
            customerId: auth.cus_id,
            origin: window.location.origin,
          },
        });
        if (data) {
          window.location.href = data.url;
        }
      }
    } catch (error) {}
  };

  const planRender = (item: any) => {
    // const resetDate = new Date(item?.createdAt);
    // const resetDateFormated = `${resetDate.getDate()} ${resetDate.toLocaleDateString(
    //   "en-US",
    //   { month: "short" }
    // )} ${resetDate.getFullYear()}`;

    return (
      <div>
        <div>
          <div className="pb-3 pt-5">
            <Progress
              percent={
                ((+item.package?.words_per_month -
                  (item.count?.words_per_month <= 0
                    ? 0
                    : item.count?.words_per_month)) /
                  item.package?.words_per_month) *
                100
              }
            />
          </div>
          <>
            {/* <p className="font-bold">
              Words usage resets on {resetDateFormated}
            </p> */}
            <br />
            <h2 className="font-bold">Writing tasks:</h2>
            <ul className="list-disc ml-8">
              <li>Words generated {renderCount("words_per_month", item)}</li>
              <li>ChatGPT per day {renderCount("messages_per_day", item)}</li>
            </ul>
            <h2 className="font-bold">Chat PDF:</h2>
            <ul className="list-disc ml-8">
              <li>Pdf max pages {item?.package.pdf_max_pages}</li>
              <li>Pdf max size {item?.package.pdf_max_size}MB</li>
              <li>
                Pdf request per day {renderCount("pdf_request_per_day", item)}
              </li>
            </ul>
            {/* <h2 className="font-bold">Shortcast Summary:</h2>
            <ul className="list-disc ml-8">
              <li>
                Fast youtube request per month{" "}
                {renderCount("fast_youtube_request_per_month", item)}
              </li>
              <li>
                Normal youtube request per month{" "}
                {renderCount("normal_youtube_request_per_month", item)}
              </li>
              <li>
                Normal youtube video length limit{" "}
                {item?.package.normal_youtbue_video_length_limit} minutes
              </li>
              <li>
                Upload request per month{" "}
                {renderCount("upload_request_per_month", item)}
              </li>
              <li>
                Upload video max size {item?.package.upload_video_size_max}MB
              </li>
            </ul> */}
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-col w-full">
        <Head />
        <div className="mt-5">
          <div className="w-full md:w-2/3 m-auto">
            <div className="w-24">
              <Button
                name="Back"
                type="fourth"
                Icon={BackIcon}
                onClick={() => navigate("/")}
              />
            </div>
          </div>
          <div className="bg-green-100 w-fit rounded-2xl py-2 px-4 text-gray-500 m-auto my-5">
            <span>33% off for Yearly</span>
          </div>
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PL_KEY}
            customer-email={auth?.email}
          ></stripe-pricing-table>
          <div className="w-full md:w-2/3 lg:w-1/2 m-auto">
            <div className="p-5">
              <div className="font-bold text-[20px]">Subscription</div>
              <div className="">
                You are currently on the{" "}
                <span className="font-bold">
                  {auth && auth.plans?.length > 0
                    ? auth.plans?.map((item: any, index: number) => (
                        <>
                          <span>{item.pro_name}</span>
                          {auth.plans.length !== 0 &&
                            index !== 1 &&
                            index !== auth.plans.length - 1 &&
                            ", "}
                        </>
                      ))
                    : "Free"}
                </span>{" "}
                plan.
              </div>
              {auth && planRender(auth.count)}
            </div>
          </div>
          <div className="w-1/3 m-auto mb-10">
            <Button
              name="Manage Subscription"
              onClick={manageSubscription}
              className="!bg-[#6366F1]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
