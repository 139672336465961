import Loading from "../loading";

interface IProps {
  Icon?: any;
  name: string;
  type?: "primary" | "secondary" | "thirt" | "fourth" | "custom";
  className?: string;
  loading?: boolean;
  disabling?: boolean;
  onClick: (e?: any) => void;
}

const View = ({
  Icon,
  name,
  type = "primary",
  className,
  loading = false,
  disabling = false,
  onClick,
}: IProps) => {
  const rendertype = (): string => {
    if (type === "primary") return "bg-typera-primary text-white";
    if (type === "secondary") return "bg-typera-secondary text-white";
    if (type === "thirt") return "bg-gray-primary text-typera-primary";
    if (type === "fourth") return "bg-white border text-typera-primary";
    return "";
  };

  return (
    <button
      disabled={disabling}
      onClick={!loading ? onClick : () => {}}
      className={`${
        type !== "custom"
          ? `flex font-extramedium w-full text-xs items-center justify-center gap-2 py-3 rounded ${rendertype()} ${className} ${
              loading && "opacity-70 cursor-not-allowed"
            } ${disabling && "!bg-gray-thirt cursor-not-allowed"}`
          : className
      }`}
    >
      {loading || (Icon && <Icon />)}
      {loading && <Loading sub={false} iStyle="w-3.5 h-3.5 text-white" />}
      <span>{name}</span>
    </button>
  );
};

export default View;
