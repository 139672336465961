import { IObject } from "types/types";

const TopPagesTable = ({ data }: IObject) => {
  return (
    <div className="relative overflow-x-auto">
      <div className="h-12 bg-white w-full flex flex-col items-end pt-2 pr-2">
        <img src="/images/filter.png" alt="" className="w-6" />
      </div>

      <div className="h-[300px] overflow-auto">
        {" "}
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Top pages
              </th>
              <th scope="col" className="px-6 py-3">
                Clicks
              </th>
              <th scope="col" className="px-6 py-3">
                Impressions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.rows.map((item: IObject, key: string) => (
              <tr key={key} className="bg-white border-b  ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {item.keys}
                </th>
                <td className="px-6 py-4 text-blue-600">{item.clicks}</td>
                <td className="px-6 py-4">{item.impressions}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopPagesTable;
