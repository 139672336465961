import { createSlice } from "@reduxjs/toolkit";
import {
  getCategoriesAsync,
  getLanguagesAsync,
  getProjectsAsync,
  getPromptsAsync,
  getProjectChatsAsync,
  getPromptChatsAsync,
  getDirectoriesAsync,
  getPlansAsync,
  getAuthsAsync,
} from "./settings.action";
import { IDirectory } from "types/responses";

export interface ISettingState {
  auth?: any;
  languages?: any;
  categories?: any;
  projects?: any;
  prompts?: any;
  project_chats?: any;
  prompt_chats?: any;
  directories?: any;
  plans?: any;
  shortcasts?: any;
  transcripts?: any;
}

const initialState: ISettingState = {
  auth: null,
  languages: [],
  projects: [],
  categories: [],
  prompts: [],
  project_chats: [],
  prompt_chats: [],
  directories: [],
  plans: [],
  shortcasts: [],
  transcripts: [],
};

export const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    addTranscriptStream: (state: ISettingState, { payload }) => {
      const { directory_id, transcript } = payload;
      state.directories = state.directories.map((item: any) => {
        if (item._id === directory_id) {
          item.init_status = "finish";
          item.transcript = transcript;
        }
        return item;
      });
    },
    updateTranscript: (state: ISettingState, { payload }) => {
      const { id, body } = payload;
      state.directories.forEach((item: any) => {
        if (item?.transcript && item?.transcript._id === id) {
          item.transcript = {
            ...item.transcript,
            ...body,
          };
        }
      });
    },
    addTranscript: (state: ISettingState, { payload }) => {
      state.transcripts = [...state.transcripts, payload];
    },
    updateCount: (state: ISettingState, { payload }) => {
      const { key, value } = payload;
      state.auth.count.count = {
        ...state.auth?.count?.count,
        [key]: value,
      };
    },
    updateAuth: (state: ISettingState, { payload }) => {
      const { key, value } = payload;
      if (key && value) {
        state.auth = {
          ...state.auth,
          [key]: value,
        };
      }
    },
    setAuth: (state: ISettingState, { payload }) => {
      state.auth = payload;
    },
    addDirectory: (state: ISettingState, { payload }) => {
      if (payload?.parent_id) {
        const parent = state.directories.find(
          (item: any) => item._id === payload.parent_id,
        );
        parent.childs = [...(parent.childs || []), payload];
      } else {
        state.directories = [payload, ...state.directories];
      }
    },
    updateShortcast: (state: ISettingState, { payload }) => {
      const { id, body } = payload;
      state.directories = state.directories
        .filter((item: any) => item.type === "shortcast")
        .map((item: any) => {
          if (item.id === id) {
            item = { ...item, ...body };
          }
          return item;
        });
    },
    updateDirectory: (state: ISettingState, { payload }) => {
      const { id, body } = payload;
      const recursive = (array: IDirectory[], target: string) => {
        for (let i = 0; i < array.length; i++) {
          if (array[i].id === id) {
            array[i] = {
              ...array[i],
              ...body,
            };
            break;
          }
          if (array[i].id !== id && array[i].type === "folder") {
            if (array[i]?.childs) {
              recursive(array[i].childs as IDirectory[], target);
            }
          }
        }
      };
      recursive(state.directories, id);
    },
    removeDirectory: (state: ISettingState, { payload }) => {
      const { id } = payload;
      const recursive = (array: IDirectory[], target: string) => {
        for (let i = 0; i < array.length; i++) {
          if (array[i].id === id) {
            array.splice(i, 1);
            break;
          }
          if (array[i].id !== id && array[i].type === "folder") {
            if (array[i]?.childs) {
              recursive(array[i].childs as IDirectory[], target);
            }
          }
        }
      };
      recursive(state.directories, id);
    },
  },
  extraReducers: {
    [`${getPlansAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getPlansAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.plans = payload;
      }
    },
    [`${getLanguagesAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getLanguagesAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.languages = setting?.["value"];
      }
    },
    [`${getCategoriesAsync.pending}`]: (
      state: ISettingState,
      { payload },
    ) => {},
    [`${getCategoriesAsync.fulfilled}`]: (
      state: ISettingState,
      { payload },
    ) => {
      const { errors } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.categories = payload;
      }
    },
    [`${getProjectsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getProjectsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.projects = payload;
      }
    },
    [`${getPromptsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getPromptsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.prompts = payload;
      }
    },
    [`${getProjectChatsAsync.pending}`]: (
      state: ISettingState,
      { payload },
    ) => {},
    [`${getProjectChatsAsync.fulfilled}`]: (
      state: ISettingState,
      { payload },
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.project_chats = payload;
      }
    },
    [`${getPromptChatsAsync.pending}`]: (
      state: ISettingState,
      { payload },
    ) => {},
    [`${getPromptChatsAsync.fulfilled}`]: (
      state: ISettingState,
      { payload },
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.prompt_chats = payload;
      }
    },
    [`${getDirectoriesAsync.pending}`]: (
      state: ISettingState,
      { payload },
    ) => {},
    [`${getDirectoriesAsync.fulfilled}`]: (
      state: ISettingState,
      { payload },
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.directories = payload;
      }
    },
    [`${getAuthsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getAuthsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.auth = payload.user;
      }
    },
  },
});

export const {
  setAuth,
  addDirectory,
  updateDirectory,
  removeDirectory,
  updateAuth,
  updateCount,
  updateShortcast,
  addTranscript,
  updateTranscript,
  addTranscriptStream,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
