import Button from "components/button";
import { ArrowIcon } from "assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import Toast from "components/toast";
import { createPortal } from "react-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "assets/loading-animation.json";
import { getDirectories } from "redux/settings/settings.selector";
import Input from "components/input";
import DirectoryService from "services/directories";
import { FileUploader } from "react-drag-drop-files";
import { uploadToS3 } from "services/s3";
import { addTranscript, updateShortcast } from "redux/settings/settings.slice";
import Dropdown from "components/dropdown";

interface ISource {
  source: string | File;
  upload_type: "youtube_fast" | "youtube_slow" | "file" | null;
  language?: string;
}

const Feature = ({
  id,
  name,
  desc,
  img,
  type,
  target,
  upload_type,
  source,
  has_language_option = false,
  onClick,
  setSource,
}: {
  id: string;
  name: string;
  desc: string;
  img: string;
  type: "link" | "file";
  target: string | null;
  upload_type: "youtube_fast" | "youtube_slow" | "file";
  source?: ISource;
  has_language_option?: boolean;
  onClick?: (e?: any) => void;
  setSource?: Dispatch<SetStateAction<ISource>>;
}) => {
  const languages = [
    { key: "en", name: "English" },
    { key: "es", name: "Spanish" },
    { key: "zh", name: "Chinese" },
    { key: "hi", name: "Hindi" },
    { key: "ar", name: "Arabic" },
    { key: "pt", name: "Portuguese" },
    { key: "bn", name: "Bengali" },
    { key: "ru", name: "Russian" },
    { key: "ja", name: "Japanese" },
    { key: "ms", name: "Malay" },
    { key: "fr", name: "French" },
    { key: "ko", name: "Korean" },
    { key: "de", name: "German" },
    { key: "ur", name: "Urdu" },
    { key: "tr", name: "Turkish" },
    { key: "it", name: "Italian" },
    { key: "vi", name: "Vietnamese" },
  ];

  const selectFileHandler = (file: File) => {
    if (file) {
      setSource && setSource({ source: file, upload_type });
    }
  };

  const actionRender = () => {
    switch (type) {
      case "link": {
        return target === id ? (
          <div className="flex gap-4">
            <Input
              onChange={(e) => {
                setSource &&
                  setSource({
                    source: e,
                    upload_type,
                  });
              }}
              placeholder="Put your url"
            />
            {has_language_option && (
              <Dropdown
                ckey="language"
                data={languages}
                onSelect={(e) => {
                  setSource &&
                    setSource((state) => ({
                      ...state,
                      language: e,
                    }));
                }}
                value={source?.language as string}
                defaultValue={"en"}
                maxHeight={200}
                className="mt-0 w-[130px]"
              />
            )}
          </div>
        ) : (
          <>
            <p className="font-bold text-typera-primary truncate">{name}</p>
            <p className="truncate w-full text-icon-default">{desc}</p>
          </>
        );
      }
      case "file": {
        return (
          <FileUploader
            name="file"
            types={["mp3", "mp4", "m4a"]}
            handleChange={selectFileHandler}
          >
            <p className="font-bold text-typera-primary truncate">
              {source && source.source instanceof File
                ? source.source.name
                : name}
            </p>
            <p className="truncate w-full text-icon-default">
              {source && source.source instanceof File
                ? `${(source.source.size / 1024 ** 2).toFixed(2)} MB`
                : desc}
            </p>
          </FileUploader>
        );
      }
    }
  };

  return (
    <div
      className="w-full border rounded-lg"
      onClick={() => onClick && onClick(id)}
    >
      <div className="p-4 flex gap-3 items-center">
        <img src={img} alt="icon" className="w-10 h-10" />
        <div className="w-4/5">{actionRender()}</div>
      </div>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const directories = useAppSelector(getDirectories);
  const { id } = useParams();
  const [targetShortcast, setTargetShortcast] = useState<any>(null);
  const [targetFeature, setTargetFeature] = useState<string | null>(null);
  const [message, setMessage] = useState<{
    show: boolean;
    type: "success" | "error" | "warn" | null;
    content: string;
  }>({
    show: false,
    type: null,
    content: "",
  });
  const [source, setSource] = useState<ISource>({
    source: "",
    upload_type: null,
    language: "en",
  });
  // const user = useAppSelector(getAuth);
  const dispatch = useAppDispatch();

  console.log({
    source,
  });

  useEffect(() => {
    if (id) {
      const shortcast = directories
        .filter((item) => item.type === "shortcast")
        .find((item: any) => item._id === id);
      if (shortcast) {
        setTargetShortcast(shortcast);
        if (shortcast.init_status === "finish") {
          navigate("transcript");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, directories]);

  const submitUploadHandler = async () => {
    try {
      if (source) {
        dispatch(
          updateShortcast({
            id: targetShortcast?._id,
            body: { init_status: "pending" },
          })
        );
        let transcript;
        if (typeof source.source === "string") {
          transcript = await DirectoryService.shortcastInitSource({
            body: {
              source: source.source?.trim(),
              upload_type: source?.upload_type,
              directory_id: targetShortcast?._id,
              language: source?.language,
            },
          });
        }
        if (typeof source.source === "object") {
          const uploaded = await uploadToS3(
            process.env.REACT_APP_S3_BUCKET as string,
            "copypress",
            source?.source
          );
          if (uploaded) {
            transcript = await DirectoryService.shortcastInitSource({
              body: {
                source: uploaded.filePath,
                upload_type: source?.upload_type,
                directory_id: targetShortcast?._id,
                language: source?.language,
              },
            });
          }
        }
        dispatch(addTranscript(transcript));
        if (targetShortcast?.transcript) {
          navigate("transcript");
        }
      }
    } catch (error: any) {
      dispatch(
        updateShortcast({
          id: targetShortcast?._id,
          body: { init_status: "not" },
        })
      );
      setMessage({
        show: true,
        content: error.errors,
        type: "warn",
      });
    }
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  const setTargetFeatureHandler = (id: string) => {
    setTargetFeature(id);
  };

  return (
    <>
      {targetShortcast?.init_status !== "pending" ? (
        <div className="w-full">
          <div className="h-1/3 bg-gray-primary">
            <div className="text-center pt-5">
              <h1>
                <p className="text-2xl md:text-5xl">
                  <strong>Welcome to</strong>
                </p>
                <p className="text-2xl md:text-5xl mt-5">
                  <strong className="bg-clip-text bg-gradient-to-r to-purple-400 from-pink-600 text-transparent">
                    Shortcast.AI
                  </strong>
                </p>
              </h1>
              <p className="mt-10 text-icon-default">
                Experience State-of-the-Art AI: Efforlessly Summarize Your
                Favorite Podcasts
              </p>
            </div>
          </div>
          <div>
            <div className="m-auto w-4/5 md:w-2/3 mt-10 md:mt-20">
              <div className="grid grid-cols-1 gap-5">
                <Feature
                  id="youtube fast"
                  target={targetFeature}
                  name="YouTube URL (Supper Fast, English only, transcript default)"
                  desc="https://www.youtube.com/watch?v=3.0&name=typera&feature=shortcast_youtube_pdf_chat_suggestion"
                  img="/images/youtube.svg"
                  type="link"
                  onClick={setTargetFeatureHandler}
                  setSource={setSource}
                  upload_type="youtube_fast"
                  has_language_option
                  source={source}
                />
                <Feature
                  id="youtube slow"
                  target={targetFeature}
                  name="YouTube URL (Slow, multiple languages, high accuracy transcript)"
                  desc="https://www.youtube.com/watch?v=3.0&name=typera&feature=shortcast_youtube_pdf_chat_suggestion"
                  img="/images/youtube.svg"
                  type="link"
                  onClick={setTargetFeatureHandler}
                  setSource={setSource}
                  upload_type="youtube_slow"
                  source={source}
                />
                <Feature
                  id="file"
                  target={targetFeature}
                  name="Upload your file"
                  desc="Drop your audio or video file here, or click to browse"
                  img="/images/upload-cloud.svg"
                  type="file"
                  onClick={setTargetFeatureHandler}
                  setSource={setSource}
                  upload_type="file"
                  source={source}
                />
              </div>
              <p className="text-icon-default text-sm mt-5">
                Don't know how Shortcast works?{" "}
                <span className="text-purple-700">View sample result</span>
              </p>
              <div className="mt-10">
                <Button
                  name="Next"
                  onClick={submitUploadHandler}
                  Icon={ArrowIcon}
                  className="!bg-purple-700 !w-20 !ml-auto flex-row-reverse"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative h-full w-full">
          <div className="absolute top-1/2 -translate-y-1/2 right-1/2 w-1/2 text-center left-1/2 -translate-x-1/2">
            <Player autoplay loop src={LoadingJson} className="h-32" />
            <p className="text-icon-default">
              Processing time takes roughly 15% of the Podcast/file duration.
              You can close this window and comeback to it later.
            </p>
          </div>
        </div>
      )}
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </>
  );
};

export default View;
