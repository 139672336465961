// import { useState } from "react";
// import HeadAvatarOption from "../../components/head-avatar-option";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setShow, sidebarSelector } from "../../redux/sidebar/sidebar.slice";
// import { createPortal } from "react-dom";
// import Modal from "components/modal";
// import Profile from "components/profile";
// import { getAuth } from "redux/settings/settings.selector";
import { NotificationIcon } from "assets/icons";

const View = () => {
  // const [showOption, setShowOption] = useState(false);
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { show } = useAppSelector(sidebarSelector);
  // const [showProfile, setShowProfile] = useState(false);
  // const auth = useAppSelector(getAuth);

  // const options = [
  //   {
  //     id: "2",
  //     key: "profile",
  //     name: "Profile",
  //     icon: "profile.svg",
  //     handle: () => {
  //       setShowProfile(true);
  //     },
  //   },
  //   {
  //     id: "3",
  //     key: "subscription",
  //     name: "Subscription",
  //     icon: "subscription.svg",
  //     handle: () => {
  //       navigate("/subscription");
  //     },
  //   },
  //   {
  //     id: "1",
  //     key: "logout",
  //     name: "Logout",
  //     icon: "logout.svg",
  //     handle: () => {
  //       localStorage.removeItem("access_token");
  //       navigate("/login");
  //     },
  //   },
  // ];

  // const handleShowOption = () => {
  //   setShowOption((state) => !state);
  // };

  return (
    <div className="fixed md:relative z-50 bg-white w-full h-fit">
      <div className="flex px-10 py-2 h-16 ">
        <div className="flex items-center">
          <button
            className="relative z-50 md:hidden"
            onClick={() => dispatch(setShow(!show))}
          >
            <AiOutlineMenu className="w-6 h-6" />
          </button>
        </div>
        <div className="w-1/2 m-auto flex items-center justify-center">
          <Link to="/">
            <img
              src="/images/copypress-logo-purple.svg"
              alt="logo"
              className="block md:hidden w-[120px] h-9"
            />
          </Link>
        </div>
        {/* <div className="flex items-center">
          <div className="flex items-center">
            <div className="px-5 border-e border-gray-300 relative hidden md:block">
              <p className="text-base text-typera-primary leading-5 font-extramedium">
                What's new
              </p>
              <span className="absolute flex h-1.5 w-1.5 -top-0.5 right-4">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#00D68F] opacity-75"></span>
                <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-[#00D68F]"></span>
              </span>
            </div>
            <div className="md:pl-5 relative right-0">
              <img
                src={auth?.avatar || "/images/avatar.png"}
                alt="avatar"
                className="w-9 h-9 rounded-full cursor-pointer"
                onClick={handleShowOption}
              />
              {showOption && (
                <div className="absolute md:-left-10 -left-16 top-12 bg-white w-32 rounded shadow-md z-50">
                  {options.map((option) => (
                    <HeadAvatarOption
                      ckey={option.key}
                      key={option.id}
                      name={option.name}
                      icon={option.icon}
                      onClick={option.handle}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div> */}
        <div className="flex items-center ">
          <p className="text-sm text-black font-semibold mr-2">What's New</p>
          <div className="flex items-center justify-center relative  w-7 h-7">
            <NotificationIcon className="w-full h-full text-gray-300 cursor-pointer hover:text-gray-500" />
            <div className="w-2 h-2 bg-red-500 rounded-full absolute top-1 right-1"></div>
          </div>
        </div>
      </div>
      {/* {showProfile &&
        createPortal(
          <Modal>
            <Profile onClose={() => setShowProfile(false)} />
          </Modal>,
          document.body
        )} */}
    </div>
  );
};

export default View;
