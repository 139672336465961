/* eslint-disable react-hooks/exhaustive-deps */
import ChatHistory from "layouts/chat-history";
import { isDesktop } from "react-device-detect";
import { Outlet } from "react-router-dom";

const View = () => {
  return (
    <div className="h-screen md:h-full flex flex-col lg:flex-row mt-20 md:mt-0">
      <div className="flex-2 bg-white m-3 mr-0">
        <Outlet />
      </div>
      {isDesktop && (
        <div className="flex-1 bg-white m-3">
          <ChatHistory />
        </div>
      )}
    </div>
  );
};

export default View;
